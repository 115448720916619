.short-info-language{
    p{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        strong{
            text-transform: capitalize;
            width: 20%;
        }

        span.first-span{
            text-align: left;
            width: 80%;
            display: block;
        }
    }
}

.list-translations{

    .tr-id{
        width: 5%;
        text-align: center;
    }

    .tr-type{
        width: 10%;
        text-align: center;
    }

    .tr-tr-group{
        width: 15%;
        text-align: center;
    }

    .tr-tr-key{
        width: 30%;
        text-align: center;
    }

    .tr-language-slug{
        width: 5%;
        text-align: center;
    }

    .tr-value{
        width: 30%;
        text-align: center;
    }

    .tr-item.new{
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
    }

    .tr-item.update{
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
    }

}