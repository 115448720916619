@import 'scss/vendors/include-media';

.guest-app {
    // background: url("../../assets/bg-login-page.jpg") no-repeat scroll center center transparent;

    main {
        display: flex;
        justify-content: center;
        flex: 1;
        width: 100%;
    }

    .container {
        width: 100%;
        height: 100%;
    }

    .block {
        max-width: 450px;
        min-width: 270px;
        margin: 0 auto;
        text-align: center;
        top: 30%;


        .row {

            &.bordered {
                padding: 16px 0;
                border-top: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity20));
                border-bottom: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity20));

                &-top {
                    @extend .bordered;
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }

            &+.row {
                margin-top: 24px;
            }
        }
    }

}