$breakpoints: ('xs-phone': 320px,
    'phone': 480px,
    'tablet': 768px,
    'desktop': 1024px,
    'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
    'print': 'print',
    'handheld': 'handheld',
    'landscape': '(orientation: landscape)',
    'portrait': '(orientation: portrait)',
    'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
    'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';

:root {

    --primary-bg-color: #{hexToStr($background)};

    --secondary-bg-color: #{hexToStr($dark-green)};
    --secondary-bg-color-rgb: #{hexToRGB($dark-green)};

    --primary-text-color: #{hexToStr($dark-green)};
    --primary-text-color-rgb: #{hexToRGB($dark-green)};

    --secondary-text-color: #{hexToStr($white)};

    --active-text-color: #{hexToStr($magenta)};

    --primary-button-bg-color: #{hexToStr($magenta)};
    --primary-button-bg-color-rgb: #{hexToRGB($magenta)};
    --active-button-bg-color: #{hexToStr($orange-red)};

    --primary-button-color: #{hexToStr($white)};
    --secondary-button-color: #{hexToStr($magenta)};
    --active-button-color: #{hexToStr($orange-red)};

    --error-color: #{hexToStr($error)};
    --success-color: #{hexToStr($success)};
    --warning-color: #{hexToStr($warning)};

    --opacity60: 0.6;
    --opacity50: 0.5;
    --opacity20: 0.2;

    --animation-speed: 0.3s;
}

body,
p {
    margin: 0;
}

ul {
    @extend %list-reset;
}

html,
body,
#root {
    min-height: 100%;
}

body {
    background-color: var(--white);
    color: var(--primary-text-color);
    font: 400 $primary-font-size $primary-font;
    line-height: $primary-line-height;
}

.guest-app,
.app {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
}

h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4em;
    color: var(--primary-text-color);
}

h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33em;
    color: var(--primary-text-color);
}

img {
    max-width: 100%;
    height: auto;
}

.color {

    &-primary {
        color: var(--primary-text-color);
    }
}

.text {
    color: var(--primary-text-color);

    &-bold {
        @extend .text;
        font-weight: bold;
    }

    &-light {
        color: rgba(var(--primary-text-color-rgb), var(--opacity60));
    }

    &-white {
        color: var(--secondary-text-color);
    }

    &-error {
        color: var(--error-color);
    }

    &-type2 {
        @extend .text;
        font-size: 14px;
        line-height: 1.29em;
    }

    &-type3 {
        @extend .text;
        font-size: 12px;
        line-height: 1.33em;

        &-light {
            @extend .text-type3,
            .text-light;
        }

        &-error {
            @extend .text-type3,
            .text-error;
        }
    }

    &-mono {
        color: rgba(var(--primary-text-color-rgb), var(--opacity50));
        font: 400 14px/1.14em $secondary-font;
        letter-spacing: 1px;

        &-bold {
            @extend .text-mono;
            font-weight: bold;
        }
    }
}

.link {

    &-text {
        @extend .text;

        &:hover {
            color: rgba(var(--primary-text-color-rgb), var(--opacity60));
        }

        &:focus {
            @extend .text;
        }

        &-bold {
            @extend .link-text,
            .text-bold;
        }
    }

    &-active {
        color: var(--secondary-button-color);

        &:hover {
            color: var(--active-button-color);
        }
    }

    &-button {
        @extend .ant-btn,
        %transition;
        display: inline-flex;
        align-items: center;
        justify-content: center;


        &-primary {
            @extend .link-button,
            .ant-btn-primary;

            &:hover {
                color: var(--primary-button-color);
            }
        }

    }
}

.ant-spin {
    color: inherit !important;
}

.ant-btn {

    &:hover {
        background-color: transparent;
    }

    &-link {
        @extend .link-text;
        font-size: 14px;
        color: var(--primary-text-color);
        font-family: inherit !important;
        height: auto;
        width: auto !important;
        min-width: initial !important;
        padding: 0;
        letter-spacing: initial;
        text-transform: initial;

    }

    &.icon {
        font-size: 24px;
        height: auto;
        width: auto;
        min-width: auto;
        padding: 5px;
        border-radius: 50%;
        color: var(--primary-text-color);

        &.rounded {
            @extend .ant-btn-default;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.rounded-primary {
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: var(--primary-button-bg-color) !important;
            color: var(--primary-button-color) !important;

            &:hover {
                background-color: var(--active-button-bg-color) !important;
                border-color: var(--active-button-bg-color) !important;
            }
        }

        .anticon {
            vertical-align: 0;
            line-height: 0;
        }

        &:focus {
            background-color: initial;
            color: var(--primary-text-color);
        }

        &:active {
            color: var(--primary-text-color);
            position: relative;
        }

        &:hover:not(:active) {
            color: rgba(var(--primary-text-color-rgb), var(--opacity60));
        }
    }

    &-default {
        @extend .ant-btn-primary;
        background-color: transparent !important;
        color: var(--secondary-button-color) !important;

        &:hover {
            color: var(--active-button-color) !important;
        }

        &:active,
        &:focus {
            @extend .ant-btn-default;
        }

    }

    &-primary {
        // background-color: var(--primary-button-bg-color);
        color: var(--primary-button-color);
        // border: 2px solid var(--primary-button-bg-color);

        &:active,
        &:focus {
            @extend .ant-btn-primary;
        }

        &:hover:not(:active) {
            background-color: var(--active-button-bg-color);
            border-color: var(--active-button-bg-color);
        }

        &[disabled]:hover,
        &[disabled] {
            color: var(--primary-button-color);
            // background-color: rgba(var(--primary-button-bg-color-rgb), var(--opacity50)) !important;
            // border-color: rgba(var(--primary-button-bg-color-rgb), var(--opacity20)) !important;
        }

    }


}

.ant-form {
    [role="alert"] {
        @extend .text-type3-error;
        padding: 0 10px;
    }

    .field-group &-item {
        margin-bottom: 0;
    }

    .field-group,
    & *:not(.field-group) &-item {

        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .field-group {
        display: flex;

        &>* {
            display: flex;
            flex-direction: column;
            flex: 1;

            .ant-col-24 {
                flex: initial;
            }
        }

        &>*+* {
            margin-left: 22px;
        }
    }

    .ant-form-item-has-error {

        .ant-input-password,
        .ant-input {
            border-color: var(--error-color) !important;
            border-width: 2px;
            box-shadow: none !important;
        }

        .ant-checkbox {
            .ant-checkbox-inner {
                border-color: var(--error-color) !important;
            }
        }
    }

    .ant-form-item {
        .ant-input-textarea-show-count:after {
            bottom: initial;
            top: -20px;
            font-weight: bold;
            font-size: 12px;
            font-family: $primary-font;
            right: 0;
        }

        &-label {
            line-height: 1em;
            padding: 0;
            text-align: left;
        }
    }

    .ant-radio-group-outline {
        display: flex;
    }

    .label {
        @extend .text-light;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 12px;
        top: 13px;
        transition: var(--animation-speed) ease all;
        z-index: 10;

        &-wrap {
            position: relative;
            margin-bottom: 5px;

            .ant-input {
                padding-top: 20px !important;
            }
        }

        &-float {
            @extend .text-type3-light;
            top: 6px;
        }
    }
}

.modal {

    &-full {
        max-width: initial !important;
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;

        .actions {
            margin-top: 40px;
            display: flex;
            justify-content: center;

            button {
                max-width: 320px;
                width: 100%;
            }
        }

        .container {
            max-width: 1024px;
            margin: 0 auto;
            padding: 90px 0;
        }

        h1 {
            margin-bottom: 30px;
        }

        h3 {
            margin-top: 30px;
            margin-bottom: 15px;
        }

        h4 {
            margin-top: 25px;
            margin-bottom: 10px;
        }

        p+p {
            margin-top: 10px;
        }

        p+ul {
            margin-top: 10px;
            margin-left: 20px;
        }

        ul {
            ul {
                margin-bottom: 15px;

                li {
                    position: relative;
                    padding-left: 20px;

                    &:before {
                        left: 0;
                        position: absolute;
                        content: "-";
                    }
                }
            }
        }

    }

    &-standard {
        min-width: 320px;
        max-width: 480px;
        width: 100% !important;
        margin: 0;
        padding: 0;

        @include media('height<=550px') {
            min-height: 530px;

            .form-actions {
                position: initial !important;
                margin: 0 !important;
            }
        }

        @include media('<=phone') {
            height: 100%;

            .form-actions {
                position: fixed;
                bottom: 25px !important;
                margin: 0 25px;

            }

        }

        h1 {
            margin-bottom: 30px;
        }

        .ant-form {

            .ant-form-item:last-child {
                margin-bottom: 0;
            }

            .form-actions {
                margin-top: 30px;
                display: flex;
                bottom: 0;
                left: 0;
                right: 0;

                &>* {
                    flex: 1;
                }
            }
        }

        .ant-modal {

            &-content,
            &-body {
                height: 100%;
            }

            &-content {
                background-color: var(--primary-bg-color);
            }

            &-body {
                padding: 40px 25px 30px;
                position: relative;

                @include media('<=phone') {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            &-close-x {
                right: 0 !important;
                top: 0 !important;
                font-size: 28px;
            }
        }

    }

    &-dark {

        .ant-modal {
            &-close-x {
                position: absolute;
                right: 20px;
                top: 20px;
                font-size: 28px;
                color: var(--secondary-text-color) !important;
                @extend %transition;

                &:hover {
                    color: var(--active-text-color) !important;
                }
            }

            &-content {
                background-color: var(--secondary-bg-color) !important;
                color: var(--secondary-text-color) !important;

                h1,
                h4 {
                    color: var(--secondary-text-color);
                }

                h3 {
                    color: var(--active-text-color);
                }

                a {
                    color: var(--secondary-text-color);
                    text-decoration: underline;

                    &:hover {
                        color: var(--active-text-color);
                    }
                }
            }
        }
    }
}

.icons-set {
    font-size: 32px;
    color: rgba(var(--primary-text-color-rgb), var(--opacity50));
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .anticon {
        margin-top: 16px;

        &:first-child {
            margin-top: initial;
        }
    }
}

.loader {
    &-block {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ant-drawer-header {
    background: #f8f9fa;
}

// .drawer-button{
//     padding: 0;
//     color: black;
//     background: none;
//     border: 0;
//     text-shadow: none;
//     box-shadow: none;
//     border-radius: 0;
//     height: auto;
// }

.drawer-button:hover {
    color: #ff905c;
    border-color: #ff905c;
}

.page {
    width: 100%;
    max-width: 2100px;
    padding: 40px 25px;

    .block {
        position: relative;
        top: 15%;

        @include media('height<=605px') {
            top: initial;
        }
    }

    .ant-tabs-nav-wrap {
        min-width: 200px;

        .ant-tabs-tab {
            padding-left: 0;
        }
    }

    &.fullscreen {
        max-width: 100%;
        display: block !important;
    }

    table {
        border-color: #DDDEE0 !important;
        border-bottom: 1px solid transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        border-top: 1px solid #eee !important;
    }

    &.single {

        .site-page-header {
            border: 1px solid #DDDEE0;
            background: #f8f9fa;
            padding: 10px 10px !important;
        }

        .single-content {
            padding: 20px 40px !important;
            background: #fff;
            border-color: #DDDEE0 !important;
            border-bottom: 1px solid transparent;
            border-right: 1px solid transparent;
            border-left: 1px solid transparent;

            .page-heading h3 {
                display: flex;
                align-items: center;

                .ant-tag {
                    margin-right: 10px;
                }
            }
        }
    }

    // &.single{
    //     padding: 25px 25px;

    //     & > .ant-col{
    //         background: #fff;
    //         padding: 15px;
    //     }

    // }

}

.ant-tag {
    margin-right: 0;
}

.page .ant-card-bordered {
    border: 1px solid #ddd;
}

// .page-companies,
// .page-users{

//     display: block !important;

// }

thead th {
    font-weight: bold !important;
    text-transform: capitalize;
    text-align: center !important;
}

.table-header {
    background: #f8f9fa;
    border-color: #DDDEE0 !important;
    // border: 1px solid transparent;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;

    h1 {
        margin: 0 !important;
    }

    .filter-header,
    .title-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .search-header {
        max-width: 250px;

        // input{
        //     font-size: 16px;
        // }

        .ant-input-group-addon {
            display: none;
        }

        .ant-input-search-button {
            height: 36px;
        }
    }

}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.w-100 {
    width: 100%;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.font {

    &-white {
        color: #FFF;
    }

    &-15 {
        font-size: 15px;

        &-white {
            @extend .font-white,
            .font-15;
        }
    }

    &-16 {
        font-size: 16px;
    }

    &-25 {
        font-size: 25px;

        &-white {
            @extend .font-white,
            .font-25;
        }
    }
}

.select-autocomplete {
    width: 100%;
}

.user-status-new {
    background-color: #ffd591;
    border: 1px solid #ff905c;
}

.user-status-active {
    background-color: #87d068;
    border: 1px solid #35B40C;
}

.user-status-block {
    background-color: #f50;
    border: 1px solid #ff0000;
}

.user-status-close {
    background-color: grey;
    border: 1px solid #d9d9d9;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.float-center {
    float: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pe-0 {
    padding-right: 0 !important;
}

.pe-1 {
    padding-right: 0.25rem !important;
}

.pe-2 {
    padding-right: 0.5rem !important;
}

.pe-3 {
    padding-right: 1rem !important;
}

.pe-4 {
    padding-right: 1.5rem !important;
}

.pe-5 {
    padding-right: 3rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.ps-0 {
    padding-left: 0 !important;
}

.ps-1 {
    padding-left: 0.25rem !important;
}

.ps-2 {
    padding-left: 0.5rem !important;
}

.ps-3 {
    padding-left: 1rem !important;
}

.ps-4 {
    padding-left: 1.5rem !important;
}

.ps-5 {
    padding-left: 3rem !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.me-0 {
    margin-right: 0 !important;
}

.me-1 {
    margin-right: 0.25rem !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.me-3 {
    margin-right: 1rem !important;
}

.me-4 {
    margin-right: 1.5rem !important;
}

.me-5 {
    margin-right: 3rem !important;
}

.me-auto {
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.ms-1 {
    margin-left: 0.25rem !important;
}

.ms-2 {
    margin-left: 0.5rem !important;
}

.ms-3 {
    margin-left: 1rem !important;
}

.ms-4 {
    margin-left: 1.5rem !important;
}

.ms-5 {
    margin-left: 3rem !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16{
    font-size: 16px !important;
}

.fs-17{
    font-size: 17px !important;
}

.fs-18{
    font-size: 18px !important;
}

.fs-20{
    font-size: 20px !important;
}

.fs-22{
    font-size: 22px !important;
}

.visit-datails-user-history {

    .visit-location-list {
        display: flex;

        b {
            width: 15%;
            display: block;
        }

        a {
            width: 85%;
            display: block;
        }
    }

}

table .analytics {
    font-size: 15px;
    border: 1px solid #DDDEE0;
    padding: 5px 10px;
    border-radius: 5px;
}

.page-locations-edit.single {

    tr .notice-approved,
    tr .warning-covid {
        font-size: 25px;
    }
}

.select-notice-status {
    min-width: 140px;
}

.warning-covid {
    color: red;
}

.notice-approved {
    color: orange;
}

.select-auto-search {
    width: 300px;
}

.create-location {
    .select-auto-search {
        width: 300px !important;
    }
}

.dropdown-filter-group {

    background: #fff;
    padding: 10px 15px;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;


    .ant-checkbox-group {
        display: flex;
        flex-direction: column;

        &>* {
            padding: 3px 0;
        }
    }

}

/* Animation red */
.amount-notice-forms-not-empty {
    -webkit-animation: amount-notice-forms-not-empty 2s linear infinite;
    animation: amount-notice-forms-not-empty 2s linear infinite;
}

@-webkit-keyframes amount-notice-forms-not-empty {
    0% {
        color: rgb(255, 255, 255);
    }

    50% {
        color: rgb(183, 65, 14);
    }

    100% {
        color: rgb(255, 255, 255);
    }
}

@keyframes amount-notice-forms-not-empty {
    0% {
        color: rgb(255, 255, 255);
    }

    50% {
        color: rgb(183, 65, 14);
    }

    100% {
        color: rgb(255, 255, 255);
    }
}

.table-skeleton {
    display: block;
    height: 17px !important;

    &.one-quarter {
        width: 25%;
    }

    &.half {
        width: 50%;
    }

    &.three-quarters {
        width: 75%;
    }

    &.full {
        width: 100%;
    }

    span {
        width: 100% !important;
        height: inherit !important;
    }

    &+.table-skeleton {
        margin-top: 10px;
    }
}

.ant-table-tbody {
    &>tr {

        &>td.from-translate {
            border-bottom: 1px solid #fff;
            background: #f0f2f5;
        }
    }
}

.select-user-auth {
    width: 150px;
}


.page.single .single-content {
    padding: 20px 40px!important;
    background: #fff;
    border-color: #dddee0!important;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;

    .ant-form-item-label{
        text-align: left;
    }
}

.button-delete{
    padding: 0px 5px !important;
}

.button-delete .anticon:hover{
    color:tomato !important;
    background: none !important;
}

.edit-link{
    color: #13444f;
}

.edit-link:hover{
    color: #1890ff;
}

.select-group-translate .ant-select-selector{
    min-width: 150px;
}

.custom-skeleton{
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
    background-size: 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
    width: 100%;
    height: 15px;
    border-radius: 4px;
    margin: 8px 0;
}

.custom-skl h3{
    margin: 0px !important;
}

.ant-pagination{
    background: #f8f9fa;
    border-color: #DDDEE0 !important;
    // border-top: 1px solid #f0f0f0;
    // border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    margin: 0 !important;
    padding: 16px 10px !important;
}

.badge-ribbon-user{
    top: 0 !important;
}

.ant-pagination-options{
    display: none;
}