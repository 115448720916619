.ant-layout-header {
    &.header {
        background-color: #fff !important;
        border-color: #eee !important;
        border: 1px solid transparent;
        display: flex;
        justify-content: space-between;
        height: auto !important;
        align-items: center;
        line-height: 30px !important;
        padding: 5px 20px;
    }
}


.header {
    .ant-dropdown-trigger {
        cursor: pointer;
    }

    .my-profile-link,
    .real-time {
        font-size: 13px;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: none;
    }

    .logo {
        display: flex;

        .bold {
            font-weight: 700;
        }

        .navbar-brand {
            color: #333;
            font-size: 20px;
            font-weight: 300;
            line-height: 50px;
            text-align: center;
        }
    }

    .logo-box {
        width: 50px;
    }
}